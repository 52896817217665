import React from 'react';
import SettingsTile from '../SettingsTile';
import AppLogo from '../../../../components/common/appLogo/AppLogo';
import EnvironmentTag from '../../../../components/common/environmentTag';
import messages from '../SettingsTile.messages';
import { ButtonGhost } from '@planview/pv-uikit';
import { Settings } from '@planview/pv-icons';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

type TeamsSettingsTileProps = {
	sandbox: boolean;
	tenantGroupId: string;
	showPrimaryLabel: boolean;
	title: string;
	imageScale: number;
};

const TeamsSettingsTile = (props: TeamsSettingsTileProps) => {
	const {
		sandbox,
		tenantGroupId,
		showPrimaryLabel = false,
		title,
		imageScale,
	} = props;

	const intl = useIntl();
	const navigate = useNavigate();
	return (
		<>
			<SettingsTile
				icon={<AppLogo app="TEAMS" scale={imageScale} />}
				environmentLabel={
					<EnvironmentTag
						sandbox={sandbox}
						secondarySandboxIsPrimary={showPrimaryLabel}
						secondarySandboxLabel={title}
					/>
				}
				actionButtons={
					<ButtonGhost
						icon={<Settings />}
						onClick={() => {
							navigate(
								`/admin/settings/services/teams/config/${tenantGroupId}`,
								{ state: { redirect: true } },
							);
						}}
					>
						{intl.formatMessage(messages.settingsButton)}
					</ButtonGhost>
				}
			/>
		</>
	);
};

export default TeamsSettingsTile;
