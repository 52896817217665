import { defineMessages } from 'react-intl';

const messages = defineMessages({
	formHeader: {
		id: 'login.signOut.title',
		description: 'header text',
		defaultMessage: 'You have been signed out of your Planview account.',
	},
	signInButton: {
		id: 'login.signOut.signInButton',
		description: 'Sign In button text',
		defaultMessage: 'Sign in',
	},
});

export default messages;
