import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '../../../../components/common/button/Button';

import messages from './SignOutForm.messages';
import { ButtonContainer, Header } from '../../../../components/common/Layout';
import { useLogout } from '../../../../hooks/useLogout';

const SignOutForm = () => {
	const navigate = useNavigate();
	const { silentLogout } = useLogout();

	// Most uses cases that get to this page is after the user has already been signed out
	// But if someone links here directly, ensure we're really signed out by fully deleting session
	// There's no harm to doing this again. We use `silentLogout` to avoid showing a toast or redirect
	// to this page (which would cause an infinite loop)
	useEffect(() => {
		void silentLogout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<Header>
				<FormattedMessage {...messages.formHeader} />
			</Header>

			<ButtonContainer>
				<ButtonPrimary
					fluid
					type="submit"
					message={messages.signInButton}
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
						navigate('/', { state: { redirect: true } });
					}}
				/>
			</ButtonContainer>
		</div>
	);
};

export default SignOutForm;
