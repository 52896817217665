import React, { ReactElement } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
	ButtonPrimary as UIButtonPrimary,
	ButtonGhost as UIButtonGhost,
	ButtonEmpty as UIButtonEmpty,
	ButtonProps,
} from '@planview/pv-uikit';
import { sizePx, spacingPx } from '@planview/pv-utilities';
import type { IconProps } from '@planview/pv-icons';

type StyledButtonProps = {
	$fluid?: boolean;
};

const StyledButton = styled(UIButtonPrimary)<StyledButtonProps>`
	${(props) => {
		return (
			props.$fluid &&
			css`
				font-weight: 900;
				height: ${sizePx.medium};
				margin-bottom: ${spacingPx.medium};
				width: 100%;
			`
		);
	}}
`;

const getButtonText = (
	intl: IntlShape,
	message?: string | MessageDescriptor,
) => {
	return typeof message === 'object' ? intl.formatMessage(message) : message;
};

type ButtonPrimaryProps = {
	message: string | MessageDescriptor;
	ariaLabel?: string;
	fluid?: boolean;
};

// BUTTONPRIMARY
export const ButtonPrimary = ({
	message,
	ariaLabel,
	fluid,
	...rest
}: ButtonPrimaryProps & ButtonProps) => {
	const intl = useIntl();
	const text = getButtonText(intl, message);
	return (
		<StyledButton aria-label={ariaLabel || text} $fluid={fluid} {...rest}>
			{text}
		</StyledButton>
	);
};

type ButtonEmptyProps = {
	message?: string | MessageDescriptor;
	ariaLabel?: string;
};

// BUTTONEMPTY
export const ButtonEmpty = ({
	message,
	ariaLabel,
	...rest
}: ButtonEmptyProps & ButtonProps) => {
	const intl = useIntl();
	const text = getButtonText(intl, message);
	return (
		<StyledButton
			as={UIButtonEmpty}
			aria-label={ariaLabel || text}
			{...rest}
		>
			{text}
		</StyledButton>
	);
};

// BUTTONGHOST
const StyledButtonGhost = styled(StyledButton)`
	border-width: 2px;

	&:hover {
		border-width: 2px;
	}
`;

type ButtonGhostProps = {
	message: string | MessageDescriptor;
	fluid?: boolean;
};

export const ButtonGhost = ({
	message,
	fluid,
	...rest
}: ButtonGhostProps & ButtonProps) => {
	const intl = useIntl();
	const text = getButtonText(intl, message);
	const GhostButton = fluid ? StyledButtonGhost : StyledButton;
	return (
		<GhostButton
			as={UIButtonGhost}
			aria-label={text}
			$fluid={fluid}
			{...rest}
		>
			{text}
		</GhostButton>
	);
};

type IconButtonProps = {
	icon: ReactElement<IconProps>;
	message: MessageDescriptor;
};

/*
 * Button with an icon and message, such as a settings icon on Settings Page > Services > OKR settings
 * For icons see @planview/pv-icons
 */
export const IconButton = ({ icon, message, ...rest }: IconButtonProps) => {
	const intl = useIntl();
	const text = intl.formatMessage(message);
	return (
		<UIButtonGhost icon={icon} aria-label={text} {...rest}>
			{text}
		</UIButtonGhost>
	);
};
