import React, { useContext, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import { put } from '../../../hooks/request/request';
import { AppContext, UserContext } from '../../../context';
import { DISMISS_AFTER_FIVE_SECONDS } from '../../../components/common/toast/Toast';
import messages from './NewUserDialog.messages';
import { default as activateMessages } from '../users/UsersPage.messages';
import { UserDetailsForm, useUserDetailsForm } from './UserDetailsForm';
import { AssignProductsSection } from './AssignProductsSection';
import { ToastType } from '../../../types/toast';
import { isMissingRoles } from '../../../helpers/util';
import { NoteContainer } from '../../../components/common/Layout';
import { UserTenantAssignment, UserUpsertDto } from '../../../types/api/users';
import { ShowToastFn } from '../../../context/appContext';
import { ApiResponse } from '../../../types/api/api';

const byTenantId = (
	appOne: UserTenantAssignment,
	appTwo: UserTenantAssignment,
) => appOne.tenantId.localeCompare(appTwo.tenantId);

const areMappingsEqual = (
	array: UserTenantAssignment[],
	otherArray: UserTenantAssignment[],
) =>
	JSON.stringify(array.sort(byTenantId)) ===
	JSON.stringify(otherArray.sort(byTenantId));

const hasInitialUserTenantAssignment = (
	initialUserTenantAssignments: UserTenantAssignment[],
	userTenantAssignmentToFind: UserTenantAssignment,
) => {
	return !!initialUserTenantAssignments.find(
		(userTenantMapping) =>
			userTenantAssignmentToFind.tenantId ===
				userTenantMapping.tenantId &&
			userTenantAssignmentToFind.application ===
				userTenantMapping.application,
	);
};

type EditUserDialogProps = {
	intl: IntlShape;
	onConfirm: () => void;
	onCancel: () => void;
	user: UserUpsertDto;
	activateMode?: boolean;
};

const EditUserDialog = (props: EditUserDialogProps) => {
	const { intl, onConfirm, user: initialUser } = props;
	const activateMode = props.activateMode ?? false;
	const userContext = useContext(UserContext);
	const appContext = useContext(AppContext);

	const { topDownUserManagementEnabled } = userContext.customer;
	const showLocalAuthOverride = userContext.user.customerSsoEnabled;
	const { enableUsernames } = appContext.featureFlags;

	const [user, setUser] = useState<UserUpsertDto>({
		...initialUser,
		localAuthOverrideEnabled:
			showLocalAuthOverride && initialUser.localAuthOverrideEnabled,
	});
	const [isSaving, setIsSaving] = useState(false);
	const form = useUserDetailsForm(user, intl, enableUsernames);

	const initialUserTenantAssignments =
		initialUser.userTenantAssignments || [];
	const currentUserTenantAssignments = user.userTenantAssignments || [];
	const isUserUnedited =
		!form.isDirty() &&
		areMappingsEqual(
			currentUserTenantAssignments,
			initialUserTenantAssignments,
		);
	const missingRoles = isMissingRoles(currentUserTenantAssignments);

	const isFormValid = !Object.entries(form.errors).length;

	const fireRequest = async (showToast: ShowToastFn) => {
		const userDto = {
			...user,
			...form.values,
		};

		const { success, message } = (await put(
			`/io/v1/user/${user.id}`,
			userDto,
		)) as ApiResponse;

		if (user.id === userContext.user.id) {
			// on edit of self, refresh all of the context/state
			appContext.refreshAppData();
		}

		const isDeprovisioning =
			initialUserTenantAssignments.length >
				currentUserTenantAssignments.length &&
			currentUserTenantAssignments.every((userTenantAssignment) =>
				hasInitialUserTenantAssignment(
					initialUserTenantAssignments,
					userTenantAssignment,
				),
			);

		const provisionMessage = isDeprovisioning
			? messages.userAppsDeprovisionIntiated
			: messages.userAppsProvisionIntiated;

		let toastMessage = intl.formatMessage(provisionMessage);

		// Build activate success toast message
		if (activateMode) {
			toastMessage = currentUserTenantAssignments.length
				? intl.formatMessage(activateMessages.activateUserSuccess) +
					' ' +
					toastMessage
				: intl.formatMessage(activateMessages.activateUserSuccess);
		}

		if (form.isDirty() || !success) {
			toastMessage = message;
		}

		showToast({
			dismissAfter: DISMISS_AFTER_FIVE_SECONDS,
			message: toastMessage,
			type: success ? ToastType.SUCCESS : ToastType.DANGER,
		});

		return success;
	};

	const save = async (showToast: ShowToastFn) => {
		const validationResult = form.validate();
		if (validationResult.hasErrors || isSaving) {
			return;
		}

		setIsSaving(true);

		const success = await fireRequest(showToast);

		if (success && onConfirm) {
			onConfirm();
		} else {
			setIsSaving(false);
		}
	};

	return (
		<AppContext.Consumer>
			{(appContext) => (
				<Modal
					id="edit-user-modal"
					size={MODAL_MEDIUM}
					onConfirm={() => save(appContext.showToast)}
					onCancel={props.onCancel}
					cancelText={intl.formatMessage(messages.cancelButton)}
					confirmText={
						activateMode
							? intl.formatMessage(
									activateMessages.activateUserButton,
								)
							: intl.formatMessage(messages.editButton)
					}
					headerText={
						activateMode
							? intl.formatMessage(
									activateMessages.activateUserItem,
								)
							: intl.formatMessage(messages.editHeader)
					}
					// Using form.errors over form.isValid() here so that users can see form errors
					disableConfirm={
						isSaving ||
						!isFormValid ||
						(!activateMode && isUserUnedited) ||
						missingRoles
					}
				>
					<UserDetailsForm
						form={form}
						showLocalAuthOverride={showLocalAuthOverride}
						showLoginAlias={
							userContext.customer.samlNameIdLookupType ===
							'LOGIN_ALIAS'
						}
						showUsername={enableUsernames}
					/>
					<AssignProductsSection
						user={user}
						setUser={setUser}
						noDefaultOptions={false}
					/>
					{activateMode && !topDownUserManagementEnabled && (
						<NoteContainer>
							{intl.formatMessage(
								activateMessages.activateUserResyncNote,
							)}
						</NoteContainer>
					)}
				</Modal>
			)}
		</AppContext.Consumer>
	);
};

EditUserDialog.displayName = 'EditUserDialog';
export default injectIntl(EditUserDialog);
