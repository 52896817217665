import React from 'react';
import { Customer, noOp, User } from '../types';
import { LoginResponse } from '../types/api/api';

// See SecurityLevel.java
export const USER = 'USER';
export const ADMIN = 'ADMIN';
export const CUSTOMER_CARE = 'CUSTOMER_CARE';
export const SUPER_ADMIN = 'SUPER_ADMIN';

export interface UserContextProps {
	user: User | Record<string, never>;
	customer: Customer | Record<string, never>;
	impersonator?: {
		id: string;
		endImpersonationUrl: string;
	};
	orgHierarchy: Customer[] | null;
	isAuthenticated: boolean;
	isAdmin: boolean;
	isCustomerCare: boolean;
	isSuperAdmin: boolean;
	isSolutionConsultant: boolean;
	processUserData: (userData: LoginResponse) => void;
	clearUserContext: () => void;
	hasImpersonator: () => boolean;
	hasCustomerCareAccess: () => boolean;
	hasSuperAdminAccess: () => boolean;
	isPvCustomer: (customerId?: string) => boolean;
	isCustomerCareViewingPvCustomer: (customerId?: string) => boolean;
	isSuperAdminViewingPvCustomer: (customerId?: string) => boolean;
	getUserFullName: (user: User) => string;
	hasPassword: (user: User) => boolean;
}

export const defaultUserContext: UserContextProps = {
	user: {},
	customer: {},
	impersonator: undefined,
	orgHierarchy: null,
	isAuthenticated: false,
	isAdmin: false,
	isCustomerCare: false,
	isSuperAdmin: false,
	isSolutionConsultant: false,
	processUserData: noOp,
	clearUserContext: noOp,
	hasImpersonator: () => false,
	hasCustomerCareAccess: () => false,
	hasSuperAdminAccess: () => false,
	isPvCustomer: () => false,
	isCustomerCareViewingPvCustomer: () => false,
	isSuperAdminViewingPvCustomer: () => false,
	getUserFullName: () => '',
	hasPassword: () => false,
};

export default React.createContext<UserContextProps>(defaultUserContext);
