import { defineMessages } from 'react-intl';

const messages = defineMessages({
	header: {
		id: 'home.profile.header',
		description: 'Header text of the home products tab',
		defaultMessage: 'Products',
	},
	section: {
		id: 'home.profile.section.profile',
		description: 'Header text of the home profile profile section',
		defaultMessage: 'Profile',
	},
	profileButton: {
		id: 'home.profile.section.profile.button',
		description: 'Profile button text',
		defaultMessage: 'Profile',
	},
	orgDomainLabel: {
		id: 'home.profile.section.profile.domain',
		description: 'Org domain label',
		defaultMessage: 'Organization Domain: {domain}',
	},
	quickLinks: {
		id: 'home.profile.section.quickLinks',
		description: 'Quick links section title text',
		defaultMessage: 'Quick Links',
	},
	changePassword: {
		id: 'home.profile.section.quickLinks.changePassword',
		description: 'Change password link text',
		defaultMessage: 'Change password',
	},
	profileHeader: {
		id: 'home.profile.profileHeader',
		description: 'page header text',
		defaultMessage: 'Profile',
	},
	logout: {
		id: 'home.profile.section.quickLinks.logout',
		description: 'Text for Sign out link in the Quick Links section',
		defaultMessage: 'Sign out',
	},
	customerSuccessCenter: {
		id: 'home.profile.section.quickLinks.customerSuccessCenter',
		description:
			'Text for Customer Success Center link in the Quick Links section',
		defaultMessage: 'Customer Success Center',
	},
	universityOfPlanview: {
		id: 'home.profile.section.quickLinks.universityOfPlanview',
		description: 'Text for University of Planview link',
		defaultMessage: 'University of Planview',
	},
	community: {
		id: 'home.profile.section.quickLinks.community',
		description: 'Text for Customer Community link',
		defaultMessage: 'Customer Community',
	},
});

export default messages;
