import {
	AppContextProps,
	CustomerContextProps,
	GroupsContextProps,
} from '../../../context';

export const getGroups = (
	appContext: AppContextProps,
	customerContext: CustomerContextProps,
	groupsContext: GroupsContextProps,
) => {
	let systemGroups = groupsContext.systemGroups;

	// filter out the internal groups if the customer is not PLANVIEW
	if (customerContext.customer.id !== 'PLANVIEW') {
		const { internalGroupIds } = appContext;
		systemGroups = systemGroups.filter(
			(group) => !internalGroupIds.includes(group.id),
		);
	}

	return systemGroups;
};
