import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
	ADAPTIVEWORK,
	ADAPTIVEWORK_LIGHT,
	ADVISOR,
	ADVISOR_LIGHT,
	COMMUNITY,
	COMMUNITY_LIGHT,
	COPILOT,
	COPILOT_LIGHT,
	E1_PRM,
	E1_PRM_LIGHT,
	EA,
	EA_LIGHT,
	HUB,
	HUB_LIGHT,
	IDEAPLACE,
	IDEAPLACE_LIGHT,
	LAUNCHPAD,
	LEANKIT,
	LEANKIT_LIGHT,
	LOGBOOK,
	LOGBOOK_LIGHT,
	OKRS,
	OKRS_LIGHT,
	PLANVIEW_ME,
	PLANVIEW_ME_LIGHT,
	PPMPRO,
	PPMPRO_LIGHT,
	PROJECTPLACE,
	PROJECTPLACE_LIGHT,
	PVADMIN,
	PVADMIN_LIGHT,
	ROADMAPS,
	ROADMAPS_LIGHT,
	SUCCESS,
	SUCCESS_LIGHT,
	TEAMS,
	TEAMS_LIGHT,
	UP,
	UP_LIGHT,
	VIZ,
	VIZ_LIGHT,
} from '../../../assets';
import messages from './AppLogo.messages';
import { ApplicationName } from '../../../types';
import { useTheme } from '@planview/pv-utilities';

type AppLogoDefinition = {
	logo: string;
	alt: MessageDescriptor;
	height: number;
	width: number;
};

type AppLogo = {
	[key in ApplicationName]: AppLogoDefinition;
};

// Height & width here are determined by the original svg viewbox
const productLogos = (scale: number, isDarkMode: boolean): AppLogo => ({
	ADAPTIVEWORK: {
		logo: isDarkMode ? ADAPTIVEWORK_LIGHT : ADAPTIVEWORK,
		alt: messages.adaptiveWork,
		height: Math.round(176.9 * scale),
		width: Math.round(724.6 * scale),
	},
	ADVISOR: {
		logo: isDarkMode ? ADVISOR_LIGHT : ADVISOR,
		alt: messages.advisor,
		height: Math.round(152.6 * scale),
		width: Math.round(524.5 * scale),
	},
	COMMUNITY: {
		logo: isDarkMode ? COMMUNITY_LIGHT : COMMUNITY,
		alt: messages.community,
		height: Math.round(144.88 * scale),
		width: Math.round(844.17 * scale),
	},
	COPILOT_ADMIN: {
		logo: isDarkMode ? COPILOT_LIGHT : COPILOT,
		alt: messages.copilotAdmin,
		height: Math.round(175.9 * scale),
		width: Math.round(539.4 * scale),
	},
	E1_PRM: {
		logo: isDarkMode ? E1_PRM_LIGHT : E1_PRM,
		alt: messages.e1PRM,
		height: Math.round(176.5 * scale),
		width: Math.round(543.9 * scale),
	},
	EA: {
		logo: isDarkMode ? EA_LIGHT : EA,
		alt: messages.ea,
		height: Math.round(155.1 * scale),
		width: Math.round(530.3 * scale),
	},
	HUB: {
		logo: isDarkMode ? HUB_LIGHT : HUB,
		alt: messages.hub,
		height: Math.round(156.6 * scale),
		width: Math.round(526.6 * scale),
	},
	LAUNCHPAD: {
		logo: LAUNCHPAD,
		alt: messages.launchpad,
		height: Math.round(175.9 * scale),
		width: Math.round(580.2 * scale),
	},
	LEANKIT: {
		logo: isDarkMode ? LEANKIT_LIGHT : LEANKIT,
		alt: messages.leanKit,
		height: Math.round(180.5 * scale),
		width: Math.round(581.4 * scale),
	},
	LOGBOOK: {
		logo: isDarkMode ? LOGBOOK_LIGHT : LOGBOOK,
		alt: messages.logBook,
		height: Math.round(30 * scale),
		width: Math.round(76 * scale),
	},
	LOGBOOK_REPORTING: {
		logo: isDarkMode ? LOGBOOK_LIGHT : LOGBOOK,
		alt: messages.logBook,
		height: Math.round(30 * scale),
		width: Math.round(76 * scale),
	},
	OKRS: {
		logo: isDarkMode ? OKRS_LIGHT : OKRS,
		alt: messages.okrs,
		height: Math.round(154.4 * scale),
		width: Math.round(535.4 * scale),
	},
	OKRS_REPORTING: {
		logo: isDarkMode ? OKRS_LIGHT : OKRS,
		alt: messages.okrs,
		height: Math.round(154.4 * scale),
		width: Math.round(535.4 * scale),
	},
	PLANVIEW_ME: {
		logo: isDarkMode ? PLANVIEW_ME_LIGHT : PLANVIEW_ME,
		alt: messages.planviewMe,
		height: Math.round(70 * scale),
		width: Math.round(638 * scale),
	},
	PPMPRO: {
		logo: isDarkMode ? PPMPRO_LIGHT : PPMPRO,
		alt: messages.ppmPro,
		height: Math.round(153.6 * scale),
		width: Math.round(529.7 * scale),
	},
	PROJECTPLACE: {
		logo: isDarkMode ? PROJECTPLACE_LIGHT : PROJECTPLACE,
		alt: messages.projectPlace,
		height: Math.round(172.3 * scale),
		width: Math.round(649.4 * scale),
	},
	PVADMIN: {
		logo: isDarkMode ? PVADMIN_LIGHT : PVADMIN,
		alt: messages.planviewAdmin,
		height: Math.round(152.6 * scale),
		width: Math.round(530.5 * scale),
	},
	ROADMAPS: {
		logo: isDarkMode ? ROADMAPS_LIGHT : ROADMAPS,
		alt: messages.roadmaps,
		height: Math.round(175.9 * scale),
		width: Math.round(580.2 * scale),
	},
	SUCCESS: {
		logo: isDarkMode ? SUCCESS_LIGHT : SUCCESS,
		alt: messages.success,
		height: Math.round(129.08 * scale),
		width: Math.round(844.17 * scale),
	},
	TEAMS: {
		logo: isDarkMode ? TEAMS_LIGHT : TEAMS,
		alt: messages.teams,
		height: Math.round(26 * scale),
		width: Math.round(80 * scale),
	},
	TEAMTAP: {
		logo: isDarkMode ? IDEAPLACE_LIGHT : IDEAPLACE,
		alt: messages.teamtap,
		height: Math.round(180.4 * scale),
		width: Math.round(527.2 * scale),
	},
	UP: {
		logo: isDarkMode ? UP_LIGHT : UP,
		alt: messages.up,
		height: Math.round(127.49 * scale),
		width: Math.round(620.02 * scale),
	},
	VIZ: {
		logo: isDarkMode ? VIZ_LIGHT : VIZ,
		alt: messages.viz,
		height: Math.round(175.9 * scale),
		width: Math.round(595.5 * scale),
	},
});

type AppLogoProps = {
	app?: ApplicationName;
	scale?: number;
};

const AppLogo = (props: AppLogoProps) => {
	const { app, scale = 0.25 } = props;
	const intl = useIntl();

	const { theme } = useTheme();
	const isDarkMode = theme === 'dark';

	if (!app) {
		return;
	}

	const productLogo = productLogos(scale, isDarkMode)[app];
	if (!productLogo) {
		return;
	}

	const { logo, alt, height, width } = productLogo;
	return (
		<img
			src={logo}
			alt={intl.formatMessage(alt)}
			height={height}
			width={width}
		/>
	);
};

export default AppLogo;
