import { defineMessages } from 'react-intl';

const messages = defineMessages({
	firstNameColumn: {
		id: 'admin.productDetails.mapped.column.firstName',
		description: 'Label for First Name column for Users Grids',
		defaultMessage: 'First name',
	},
	lastNameColumn: {
		id: 'admin.productDetails.mapped.column.lastName',
		description: 'Label for Last Name column for Users Grids',
		defaultMessage: 'Last name',
	},
	emailColumn: {
		id: 'admin.productDetails.mapped.column.email',
		description: 'Label for Email column for Users Grids',
		defaultMessage: 'Email',
	},
	usernameColumn: {
		id: 'admin.productDetails.mapped.column.username',
		description: 'Label for username column for Users Grids',
		defaultMessage: 'Username',
	},
	addedOnColumn: {
		id: 'admin.productDetails.mapped.column.addedOn',
		description: 'Label for Added On column for Users Grids',
		defaultMessage: 'Added on',
	},
	lastLoginAtColumn: {
		id: 'admin.productDetails.mapped.column.lastLoginAtColumn',
		description: 'Label for Last login column for Users Grids',
		defaultMessage: 'Last login',
	},
	roleColumn: {
		id: 'admin.productDetails.mapped.column.role',
		description: 'Label for Role column for Users Grids',
		defaultMessage: 'Product role',
	},
	syncUsersButton: {
		id: 'admin.users.button.syncUsers',
		description: 'Sync Users button text',
		defaultMessage: 'Sync Users',
	},
	refreshSyncStatusButton: {
		id: 'admin.users.button.refreshSyncStatus',
		description: 'Refresh Sync Status button text',
		defaultMessage: 'Refresh Sync Status',
	},
	lastSynced: {
		id: 'admin.users.lastSynced',
		description:
			'Text of last sync date with time, for a completed sync job',
		defaultMessage:
			'Last sync: {syncDate}. User records processed: {syncCount}',
	},
	lastSyncedInProgress: {
		id: 'admin.users.lastSyncedInProgress',
		description:
			'Text of last sync date with time, for an in progress sync job',
		defaultMessage: 'Sync in progress. User records processed: {syncCount}',
	},
	lastSyncedNever: {
		id: 'admin.users.lastSyncedNever',
		description: 'Text of last sync date time when never synced',
		defaultMessage: 'Last sync: none',
	},
	tenantSyncInfo: {
		id: 'admin.users.tenantSyncInfo',
		description: 'Text of success / non failure response to Sync users',
		defaultMessage:
			'A background job to sync users will start in approximately 1 minute.',
	},
	bulkActionsButton: {
		id: 'admin.users.button.bulkActionsButton',
		description:
			'Text for bulk actions button for foundation app product details',
		defaultMessage: 'Bulk actions',
	},
	licenseTypeColumn: {
		id: 'admin.productDetails.mapped.column.licenseType',
		description: 'Label for subscription type column for Users Grids',
		defaultMessage: 'Subscription type',
	},
	userSearch: {
		id: 'admin.productDetails.mapped.filter.userSearch',
		description: 'Label for header of filter on user grid',
		defaultMessage: 'User',
	},
	readOnlyMessage: {
		id: 'admin.productDetails.mapped.tooltip.readOnlyMessage',
		description:
			'Tooltip message for readonly roles on the product user grid',
		defaultMessage:
			'{roleName} is a fixed role. To modify this role, you must open a support case.',
	},
	removeMapping: {
		id: 'admin.productDetails.mapped.removeMapping',
		description: 'Remove Mapping menu item text',
		defaultMessage: 'Remove Mapping',
	},
	removeMappings: {
		id: 'admin.productDetails.mapped.removeMappings',
		description: 'Remove Mappings modal header text',
		defaultMessage: 'Remove Mappings',
	},
	removeMappingsConfirmation: {
		id: 'admin.productDetails.mapped.removeMappingsConfirmation',
		description: 'Remove Mappings confirmation modal message',
		defaultMessage: 'This will remove the {count} selected mapping(s).',
	},
	yesButton: {
		id: 'admin.productDetails.mapped.removeMapping.confirmation.yes',
		description: 'Button text for Yes',
		defaultMessage: 'Yes',
	},
	noButton: {
		id: 'admin.productDetails.mapped.removeMapping.confirmation.no',
		description: 'Button text for No',
		defaultMessage: 'No',
	},
	removeUserMappingSuccess: {
		id: 'admin.productDetails.mapped.removeUserMappingSuccess',
		description:
			'Text of success / non failure response to make remove mapping for user',
		defaultMessage:
			'The selected users have been unmapped from this product instance.',
	},
});

export default messages;
