import React from 'react';
import {
	Application,
	ApplicationName,
	FeatureFlag,
	noOp,
	Region,
	TenantGroup,
} from '../types';
import { LoginResponse } from '../types/api/api';
import { Toast } from '../types/toast';

export interface AvailableApplication {
	app: ApplicationName;
	requiresConnectUri: boolean;
	addSandboxSupported: boolean;
	productName: string;
}

export type ShowToastFn = (toast: Toast) => void;

export type ApplicationsById = { [id: string]: Application };

export type AppDetails = {
	version: string | null;
	forceRefresh: boolean;
};

/**
 * featureFlags listed in same order a FeatureFlag.java
 */
export interface AppContextProps {
	appDetails: AppDetails;
	applications: Application[];
	applicationsById: ApplicationsById;
	availableApplications: AvailableApplication[];
	availableRegions: Region[];
	environment: string;
	featureFlags: FeatureFlag;
	needsLogin: boolean;
	overviewApplications: Application[];
	region: Region | '';
	tenantGroupMap: Map<string, TenantGroup>;
	internalGroupIds: string[];
	toast?: Toast;
	showToast: ShowToastFn;
	addApplications: (initialApplications: Application[]) => void;
	processAppData: (userData: LoginResponse) => void;
	checkAppVersion: (newVersion?: string) => void;
	scheduleRefreshAppData: () => void;
	setNeedsLogin: (needsLogin: boolean) => void;
	refreshAppData: () => void;
	setLoginPollingEnabled: (loginPollingEnabled: boolean) => void;
	idle: boolean;
}

export const defaultAppContext: AppContextProps = {
	appDetails: {
		version: null,
		forceRefresh: false,
	},
	applications: [],
	applicationsById: {},
	availableApplications: [],
	availableRegions: [],
	environment: '',
	featureFlags: {},
	needsLogin: false,
	overviewApplications: [],
	region: '',
	tenantGroupMap: new Map<string, TenantGroup>(),
	internalGroupIds: [],
	toast: undefined,
	showToast: noOp,
	addApplications: noOp,
	processAppData: noOp,
	checkAppVersion: noOp,
	scheduleRefreshAppData: noOp,
	setNeedsLogin: noOp,
	refreshAppData: noOp,
	setLoginPollingEnabled: noOp,
	idle: false,
};

export default React.createContext<AppContextProps>(defaultAppContext);
