import React, { ReactNode, useContext, useState } from 'react';
import { AppContext } from '../../../../context';
import { useIntl } from 'react-intl';
import { ToastType } from '../../../../types/toast';
import { logError, post } from '../../../../hooks/request/request';
import { CredentialsDtoResponse } from '../../../../types/api/tokens';
import messages from './ClientCredentials.messages';
import { Combobox, Form, Input } from '@planview/pv-form';
import { Modal, MODAL_MEDIUM } from '@planview/pv-uikit';
import CopyableInput from '../../../../components/common/input/CopyableInput';
import styled from 'styled-components';
import { theme, spacingPx, text } from '@planview/pv-utilities';
import { ApplicationOption } from './ClientCredentialsTypes';
import modalMessages from '../../../../components/common/modal/Modal.messages';
import { useForm } from '@mantine/form';

const Body = styled.div`
	${text.regular};
	margin-bottom: ${spacingPx.small};
`;

const Warning = styled.div`
	${text.regular};
	color: ${theme.error400};
`;

type NewClientCredentialsModalProps = {
	onConfirm: (show: boolean) => void;
	onCreated: () => void;
	availableApplications: ApplicationOption[];
};

/**
 * Gets the application options grouped by TenantGroup
 */
const getApplicationOptions = (availableApplications: ApplicationOption[]) => {
	const options: {
		label: string;
		options: { label: string; value: number }[];
	}[] = [];
	availableApplications.forEach((application, index) => {
		const tenantGroup = application.tenantGroup;
		const tenantGroupIndex = options.findIndex(
			(option) => option.label === tenantGroup.title,
		);
		if (tenantGroupIndex === -1) {
			options.push({
				label: tenantGroup.title,
				options: [
					{
						label: application.displayText,
						value: index,
					},
				],
			});
		} else {
			options[tenantGroupIndex].options.push({
				label: application.displayText,
				value: index,
			});
		}
	});
	return options;
};

/**
 * Represents a form for generating OAuth2 credentials.
 */
const NewClientCredentialsModal = (props: NewClientCredentialsModalProps) => {
	const { showToast } = useContext(AppContext);
	const intl = useIntl();
	const { onConfirm, onCreated, availableApplications } = props;

	const [showingResult, setShowingResult] = useState(false);
	const [id, setId] = useState('');
	const [secret, setSecret] = useState('');

	const createApiToken = async ({
		description,
		applicationOption,
	}: {
		description: string;
		applicationOption: ApplicationOption;
	}) => {
		const { application, tenantId, scope, tenantGroup } = applicationOption;

		const { id, secret, success, message } = (await post(
			`/io/v1/credentials`,
			{
				tenantGroupId: tenantGroup.tenantGroupId,
				tenantId,
				scope: scope?.id,
				application,
				description,
			},
		)) as CredentialsDtoResponse;
		if (success) {
			setShowingResult(true);
			setId(id);
			setSecret(secret || '');
			onCreated();
		} else {
			await logError(`Error generating credentials: ${message}`);
			let errorMessage = intl.formatMessage(
				messages.generateCredentialsFailure,
			);
			if (message) {
				errorMessage = `${errorMessage} ${message}`;
			}
			showToast({
				message: errorMessage,
				type: ToastType.DANGER,
			});
		}
	};

	const availableOptions = getApplicationOptions(availableApplications);

	const onConfirmButtonClick = () => {
		if (showingResult) {
			onConfirm(false);
		} else {
			const application =
				availableApplications[form.values.application?.value as number];

			void createApiToken({
				description: form.values.description,
				applicationOption: application,
			});
		}
	};

	const requiredFieldValidationText = intl.formatMessage(
		modalMessages.required,
	);

	const form = useForm({
		initialValues: {
			description: '',
			application: null as { label: string; value: number } | null,
		},
		validate: {
			description: (value) =>
				!value ? requiredFieldValidationText : null,
			application: (value) =>
				!value ? requiredFieldValidationText : null,
		},
		validateInputOnBlur: true,
	});

	return (
		<>
			<Modal
				headerText={
					showingResult
						? intl.formatMessage(messages.generatedTitle)
						: intl.formatMessage(messages.createTitle)
				}
				initialFocusId="description-input"
				confirmText={
					showingResult
						? intl.formatMessage(modalMessages.close)
						: intl.formatMessage(messages.createButton)
				}
				cancelText={
					showingResult
						? undefined
						: intl.formatMessage(modalMessages.cancel)
				}
				onConfirm={() => onConfirmButtonClick()}
				onCancel={() => onConfirm(false)}
				disableConfirm={!form.isValid()}
				size={MODAL_MEDIUM}
			>
				{showingResult ? (
					<>
						<Body>
							<CopyableInput
								label={intl.formatMessage(messages.idLabel)}
								value={id}
							/>
						</Body>
						<Body>
							<CopyableInput
								label={intl.formatMessage(
									messages.generatedSecretLabel,
								)}
								value={secret}
							/>
						</Body>
						<Warning>
							{intl.formatMessage(
								messages.generatedCredentialsWarningMessage,
								{
									strong: (parts: ReactNode[]) => (
										<strong>{parts}</strong>
									),
								},
							)}
						</Warning>
						<Body>
							<p>
								{intl.formatMessage(
									messages.generatedCredentialsCopyInstructions,
								)}
							</p>
						</Body>
					</>
				) : (
					<>
						<Form label="Create client credentials">
							<Input
								id="description-input"
								label={{
									text: intl.formatMessage(
										messages.descriptionLabel,
									),
									requiredTooltip:
										requiredFieldValidationText,
								}}
								placeholder={intl.formatMessage(
									messages.descriptionPlaceholder,
								)}
								withAsterisk={true}
								{...form.getInputProps('description')}
							/>
							<Combobox
								label={{
									text: intl.formatMessage(
										messages.applicationLabel,
									),
									requiredTooltip:
										requiredFieldValidationText,
								}}
								options={availableOptions}
								clearable={false}
								withAsterisk={true}
								{...form.getInputProps('application')}
							/>
						</Form>
					</>
				)}
			</Modal>
		</>
	);
};

export default NewClientCredentialsModal;
