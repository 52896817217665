import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { AppContext } from '../../../context';
import OKRSettingsTile from './OKRs/OKRSettingsTile';
import PlanviewMeSettingsTile from './PlanviewMe/PlanviewMeSettingsTile';
import messages from './ServiceSettings.messages';
import styled from 'styled-components';
import { spacingPx } from '@planview/pv-utilities';
import LogBookSettingsTile from './LogBook/LogBookSettingsTile';
import TeamsSettingsTile from './Groups/TeamsSettingsTile';

const SettingsTileContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: space-between;
	row-gap: ${spacingPx.small};
	padding: ${spacingPx.large} ${spacingPx.large} 0 ${spacingPx.large};
`;

const IMAGE_SCALE: number = 0.18;

// A react container for showing service settings tiles
const ServiceSettings = () => {
	const { tenantGroupMap, featureFlags, applications, refreshAppData } =
		useContext(AppContext);
	const { enableLogbook, enableGroups: showTeamsTile } = featureFlags;

	// ensure we have latest application data for the user, since some permission checks require that
	useEffect(() => {
		refreshAppData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let hasAnySettingsTiles = false;
	const settingsTiles = [];
	if (tenantGroupMap.size > 0) {
		for (const tenantGroup of tenantGroupMap.values()) {
			const { tenantGroupId, sandbox, primary, title } = tenantGroup;

			const hasPortfoliosTenant = applications.some(
				(application) =>
					application.tenantGroupId === tenantGroupId &&
					application.app === 'E1_PRM',
			);

			const hasAgilePlaceTenant = applications.some(
				(application) =>
					application.tenantGroupId === tenantGroupId &&
					application.app === 'LEANKIT',
			);

			const hasPlanviewMeTenant = applications.some(
				(application) =>
					application.tenantGroupId === tenantGroupId &&
					application.app == 'PLANVIEW_ME',
			);

			const hasProjectPlaceTenant = applications.some(
				(application) =>
					application.tenantGroupId === tenantGroupId &&
					application.app === 'PROJECTPLACE',
			);

			// always show the OKRs Settings Tile
			const showOkrSettingsTile = true;

			// only show the Planview Me Settings Tile if at least one of the following tenants exist:
			// * PlanviewMe tenant
			// * Portfolios tenant
			// and the tenant group is not a sandbox (production) or is the primary sandbox
			const tenantGroupIsProductionOrPrimarySandbox = !sandbox || primary;
			const showPlanviewMeSettingsTile =
				(hasPlanviewMeTenant || hasPortfoliosTenant) &&
				tenantGroupIsProductionOrPrimarySandbox;

			// only show the Logbook Settings Tile if the feature flag is enabled and at least one of the following tenants exist:
			// * AgilePlace tenant
			// * ProjectPlace tenant
			// * Portfolios tenant
			const showLogbookTile =
				enableLogbook &&
				(hasAgilePlaceTenant ||
					hasProjectPlaceTenant ||
					hasPortfoliosTenant);

			settingsTiles.push(
				<SettingsTileContainer key={tenantGroupId}>
					{showPlanviewMeSettingsTile ? (
						<PlanviewMeSettingsTile
							sandbox={sandbox}
							tenantGroupId={tenantGroupId}
							showPrimaryLabel={primary}
							title={title}
							imageScale={IMAGE_SCALE}
						/>
					) : null}
					{showOkrSettingsTile ? (
						<OKRSettingsTile
							sandbox={sandbox}
							tenantGroupId={tenantGroupId}
							showPrimaryLabel={primary}
							title={title}
							imageScale={IMAGE_SCALE}
						/>
					) : null}
					{showLogbookTile ? (
						<LogBookSettingsTile
							sandbox={sandbox}
							tenantGroupId={tenantGroupId}
							showPrimaryLabel={primary}
							title={title}
							imageScale={1.18}
						/>
					) : null}
					{showTeamsTile ? (
						<TeamsSettingsTile
							sandbox={sandbox}
							tenantGroupId={tenantGroupId}
							showPrimaryLabel={primary}
							title={title}
							imageScale={1}
						/>
					) : null}
				</SettingsTileContainer>,
			);

			// if we had any settings tiles added as a part of this tenant group, set hasAnySettingsTiles to true
			hasAnySettingsTiles =
				hasAnySettingsTiles ||
				showOkrSettingsTile ||
				showPlanviewMeSettingsTile;
		}
	}

	if (hasAnySettingsTiles) {
		return <>{settingsTiles}</>;
	} else {
		return (
			<SettingsTileContainer>
				<FormattedMessage {...messages.missingProductsMessage} />
			</SettingsTileContainer>
		);
	}
};

export default ServiceSettings;
