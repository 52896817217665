import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import qs from 'qs';
import { Combobox, Form, Input, Label } from '@planview/pv-form';
import { ButtonPrimary } from '../../../../components/common/button/Button';
import { ButtonContainer, Header } from '../../../../components/common/Layout';
import { login, post } from '../../../../hooks/request/request';
import {
	getValidationFunction,
	minPasswordLength,
} from '../../../../helpers/validation';
import messages from './LoginForm.messages';
import { AppContext, UserContext } from '../../../../context';
import { ToastType } from '../../../../types/toast';
import styled from 'styled-components';
import { color, spacingPx } from '@planview/pv-utilities';
import { useNavigate } from 'react-router-dom';
import { getRandomNumber } from '../../../../helpers/util';
import { Link } from '../../../../components/common/Link';
import { Edit, Review } from '@planview/pv-icons';
import { ButtonEmpty } from '@planview/pv-uikit';
import serverMessages from '../../../../messages/server';

const EmailInput = styled.div`
	display: flex;
	div:first-child {
		flex: auto;
	}
`;

const OrgInput = styled.div`
	display: flex;
`;

const EditIconHolder = styled.div`
	padding-left: ${spacingPx.xsmall};
	padding-top: 32px;
`;

const ForgotPasswordLink = styled(Link)`
	color: ${color.secondary700};
	margin-bottom: ${spacingPx.medium};
`;
const PasswordInputWrapper = styled.div`
	display: flex;
	align-items: center;
	div:first-child {
		flex: auto;
	}
	input::-ms-reveal {
		display: none;
	}
`;

const TogglePasswordButton = styled.div`
	padding-left: ${spacingPx.xsmall};
	padding-top: 24px;
`;

const isEmailValid = (intl, value) => {
	const validate = getValidationFunction(
		{
			type: 'email',
		},
		intl,
	);
	return !validate(value);
};

function LoginForm(props) {
	const appContext = useContext(AppContext);
	const userContext = useContext(UserContext);
	const intl = useIntl();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [usernameDisabled, setUsernameDisabled] = useState(false);
	const [showOrgSelection, setShowOrgSelection] = useState(false);
	const [organizationDisabled, setOrganizationDisabled] = useState(false);
	const [username, setUsername] = useState(props.username);
	const [domain, setDomain] = useState(props.domain);
	const [orgSelectionEnabled, setOrgSelectionEnabled] = useState(
		props.orgSelectionEnabled,
	);

	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [showCaptcha, _setShowCaptcha] = useState(false);
	const [captcha, setCaptcha] = useState('');
	const [customers, setCustomers] = useState({});
	const [cacheBuster, setCacheBuster] = useState(getRandomNumber());

	const [customerOptions, setCustomerOptions] = useState(null);
	const [selectedCustomerOption, setSelectedCustomerOption] = useState(null);

	const emailValid = isEmailValid(intl, username);
	const passwordValid = showPassword
		? password.length >= minPasswordLength
		: true;
	const captchaValid = showCaptcha ? captcha.length >= 1 : true;
	const isFormValid = emailValid && passwordValid && captchaValid;

	const setShowCaptcha = (value) => {
		_setShowCaptcha(value);
		setCacheBuster(new Date().getTime());
		setCaptcha('');
	};

	const getNextStep = async (username, domain) => {
		const { message, success, ...rest } = await post(
			'/api/loginsso/start',
			{
				username,
				domain,
				captcha,
			},
			true,
		);
		if (!success) {
			// Any errors while checking for username (network error, service down etc.)
			const errorMessage =
				message || intl.formatMessage(serverMessages.unexpectedError);
			appContext.showToast({
				message: errorMessage,
				type: ToastType.DANGER,
			});
		}
		return rest;
	};

	const onCustomerChange = (option, customerListArg) => {
		setSelectedCustomerOption(option);
		const customerDomain = option.value;
		setDomain(customerDomain);
		const customerList = customerListArg || customers;
		const customer = customerList.find(
			(cust) => cust.domain === customerDomain,
		);
		setShowPassword(!customer.sso);
	};

	const showCustomerSelector = (data) => {
		const { customers, defaultCustomerDomain, orgSelectionEnabled } = data;
		setOrgSelectionEnabled(orgSelectionEnabled);
		setShowOrgSelection(true);
		setCustomers(customers);

		const opts = customers?.map((customer) => {
			return {
				label: customer.title,
				value: customer.domain,
			};
		});
		setCustomerOptions(opts);

		if (defaultCustomerDomain) {
			if (orgSelectionEnabled && customers) {
				const defaultCustomerOption = opts.find(
					(opt) => opt.value === defaultCustomerDomain,
				);
				if (defaultCustomerOption) {
					onCustomerChange(defaultCustomerOption, customers);
				}
			}
			setDomain(defaultCustomerDomain);
		}
	};

	const checkNextStep = async (username, domain) => {
		if (!emailValid) {
			return;
		}

		setUsernameDisabled(true);
		setLoading(true);

		const { nextStep, data } = await getNextStep(username, domain);

		setLoading(false);
		setShowPassword(false);
		setShowCaptcha(false);

		switch (nextStep) {
			case 'PASSWORD':
				setShowPassword(true);
				break;
			case 'SSO':
				// Send to the redirectUrl if the user is configured for SSO
				window.location.replace(data.redirectUrl);
				break;
			case 'PROMPT_CUSTOMER':
				showCustomerSelector(data);
				break;
		}
	};

	const doLogin = async () => {
		setLoading(true);

		const customerDomain = selectedCustomerOption?.value;
		const loginDomain = orgSelectionEnabled ? customerDomain : domain;

		const {
			success,
			message,
			redirectUrl,
			showCaptcha: captchaFlag,
		} = await login(
			{ username, password, captcha, domain: loginDomain },
			userContext,
			appContext,
		);
		if (success) {
			if (redirectUrl) {
				// We want to redirect to a different destination URL (SAML SSO login case)
				window.location.replace(redirectUrl);
			} else {
				navigate('/home', { state: { redirect: true } });
			}
		} else {
			// This would be 404, bad captcha or something
			const errorMessage =
				message === serverMessages.authenticationError.id
					? intl.formatMessage(serverMessages.authenticationError)
					: message ||
						intl.formatMessage(serverMessages.unexpectedError);
			appContext.showToast({
				message: errorMessage,
				type: ToastType.DANGER,
			});
			setPassword('');
			setShowCaptcha(!!captchaFlag);
			setLoading(false);
		}
	};

	const handleSubmit = async () => {
		props.setUsername(username);

		if (username && password && showPassword) {
			doLogin();
			return;
		}

		if (domain) {
			setOrganizationDisabled(true);
		}
		const customerDomain = selectedCustomerOption?.value;
		const loginDomain = orgSelectionEnabled ? customerDomain : domain;
		checkNextStep(username, loginDomain);
	};

	const onEmailChange = (value) => {
		setUsername(value);
	};

	const goBackToLoginStep = () => {
		setUsernameDisabled(false);
		setOrganizationDisabled(false);
		setShowOrgSelection(false);
		setShowPassword(false);
		setCustomers({});
		setPassword('');
		setDomain('');

		setCustomerOptions(null);
		setSelectedCustomerOption(null);
	};

	const goBackToCustomerSelectionStep = () => {
		setOrganizationDisabled(false);
		setShowPassword(false);
		setPassword('');
		setDomain('');
	};

	const buttonMessage = showPassword ? messages.signIn : messages.continue;

	useEffect(() => {
		if (props.username) {
			checkNextStep(props.username, '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form
			label={intl.formatMessage(messages.formHeader)}
			onSubmit={(event) => {
				event.preventDefault();
				event.stopPropagation();
				if (isFormValid) {
					handleSubmit();
				}
			}}
		>
			<Header>
				<FormattedMessage {...messages.formHeader} />
			</Header>

			<EmailInput>
				<Input
					label={intl.formatMessage(messages.email)}
					type="email"
					onChange={onEmailChange}
					defaultValue={username}
					disabled={usernameDisabled}
					nativeProps={{ autoComplete: 'email', name: 'username' }}
					selected
					withAsterisk
				/>
				{usernameDisabled && (
					<EditIconHolder>
						<ButtonEmpty
							icon={<Edit />}
							onClick={goBackToLoginStep}
							tooltip={intl.formatMessage(messages.editEmail)}
						/>
					</EditIconHolder>
				)}
			</EmailInput>

			{showOrgSelection &&
				(orgSelectionEnabled ? (
					<Combobox
						label={intl.formatMessage(messages.organization)}
						options={customerOptions}
						clearable={false}
						value={selectedCustomerOption}
						onChange={onCustomerChange}
						withAsterisk
					/>
				) : (
					<OrgInput>
						<Input
							label={intl.formatMessage(
								messages.organizationDomain,
							)}
							value={domain}
							onChange={(value) => setDomain(value)}
							disabled={organizationDisabled}
							nativeProps={{ autoComplete: 'off' }}
							selected
							withAsterisk
						/>
						{organizationDisabled && (
							<EditIconHolder>
								<ButtonEmpty
									icon={<Edit />}
									onClick={goBackToCustomerSelectionStep}
									tooltip={intl.formatMessage(
										messages.editOrganization,
									)}
								/>
							</EditIconHolder>
						)}
					</OrgInput>
				))}

			{showPassword && (
				<PasswordInputWrapper>
					<Input
						onChange={(value) => setPassword(value)}
						value={password}
						type={passwordVisible ? 'text' : 'password'}
						nativeProps={{
							autoComplete: 'current-password',
							name: 'password',
						}}
						selected={true}
						label={intl.formatMessage(messages.password)}
					/>
					<TogglePasswordButton>
						<ButtonEmpty
							icon={<Review />}
							activated={passwordVisible}
							onClick={() => setPasswordVisible(!passwordVisible)}
							tooltip={intl.formatMessage(
								messages.passwordVisible,
							)}
						/>
					</TogglePasswordButton>
				</PasswordInputWrapper>
			)}

			{showCaptcha && (
				<>
					<Label useLabelElement={false}>
						<FormattedMessage {...messages.captchaLabel} />
					</Label>
					<img
						src={`/api/captcha?cb=${cacheBuster}`}
						alt={intl.formatMessage(messages.captchaAlt)}
						style={{ marginTop: spacingPx.xsmall }}
						width="200px"
						height="80px"
					/>
					<Input
						label={intl.formatMessage(messages.captchaPlaceholder)}
						onChange={(value) => setCaptcha(value)}
						value={captcha}
						nativeProps={{
							name: 'captcha',
						}}
						required
					/>
				</>
			)}

			<ButtonContainer>
				<ButtonPrimary
					fluid
					type="submit"
					loading={loading}
					disabled={!isFormValid}
					message={buttonMessage}
				/>
			</ButtonContainer>

			{showPassword && (
				<ForgotPasswordLink
					to={{
						pathname: `/forgot-password`,
						search: qs.stringify({
							username,
							organization: domain,
							orgSelectionEnabled,
						}),
					}}
				>
					<FormattedMessage {...messages.helpLogin} />
				</ForgotPasswordLink>
			)}
		</Form>
	);
}

export default LoginForm;
