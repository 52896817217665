import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { requestWithErrorHandling } from '../../../hooks/request/request';
import UsersPage from '../../admin/users/UsersPage';
import { AppContext, CustomerContext, UserContext } from '../../../context';
import { IntlShape, useIntl } from 'react-intl';
import { ListItem, Tab, TabGroup } from '@planview/pv-uikit';
import { theme, size, spacingPx, text } from '@planview/pv-utilities';
import ToolbarToolsMenu from './ToolbarToolsMenu';
import { Toolbar } from '@planview/pv-toolbar';
import {
	Column,
	Grid,
	GridCellDefault,
	GridCellDropdownMenu,
	GridRowId,
	useGridRow,
	useLocalStoragePreferences,
} from '@planview/pv-grid';
import { DotsVertical } from '@planview/pv-icons';
import { E1_PRM } from '../../../assets';
import TileErrorMessage from '../../../components/home/productTile/TileErrorMessage';
import { CustomerDto } from '../../../types/api/customers';
import { Application, Customer, Tenant } from '../../../types';
import messages from '../organization/OrganizationsPage.messages';
import { UserTabs } from '../../admin/users/UsersTabsPage';
import { getTenantsByEnvSelector } from '../../../helpers/util';
import {
	ApplicationRowData,
	GridColumn,
	TenantRowData,
} from '../../../types/grid/grid';
import EditCustomProductDialog from './EditCustomProductDialog';
import DeleteCustomProductDialog from './DeleteCustomProductDialog';

type OrganizationInfoPageTabs = {
	USERS: string;
	DETAILS: string;
};

const TABS: OrganizationInfoPageTabs = {
	USERS: 'USERS',
	DETAILS: 'DETAILS',
};

const TabBody = styled.div`
	background-color: ${theme.backgroundNeutral0};
	flex-grow: 1;
	min-height: 0;
	padding-top: 0px;
	display: flex;
	flex-direction: column;
`;

const CustomerInfoSection = styled.div`
	color: ${theme.textPrimary};
	${text.h1};
	height: 30px;
	padding: ${spacingPx.medium};
`;

const DetailTable = styled.table`
	${text.h2};
	padding: ${spacingPx.medium};
	width: fit-content;
`;

const DetailHeader = styled.td`
	${text.semibold};
	padding: ${spacingPx.xsmall};
`;

const CustomerData = styled.td`
	${text.regular};
`;

const TenantHeader = styled.div`
	${text.h2};
	padding: 0 ${spacingPx.medium};
`;

const Container = styled.div`
	display: flex;
	overflow: hidden;
`;

type FormatDateProps = {
	intl: IntlShape;
	value: string;
};

const formatDate = ({ intl, value }: FormatDateProps) => {
	return intl.formatDate(value, {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	});
};

type CellRendererProps = { tabIndex: number; rowId: GridRowId };

type OrganizationsInfoPageParams = {
	customerId: string;
	tab: string;
};

/**
 * Page for the Customer Care's view of the users within an organization.
 */
const OrganizationInfoPage = () => {
	const { customerId, tab } = useParams<OrganizationsInfoPageParams>();
	const [customer, setCustomer] = useState<Customer>();
	const [parentTitle, setParentTitle] = useState('');
	const intl = useIntl();
	const appContext = useContext(AppContext);
	const navigate = useNavigate();
	const userContext = useContext(UserContext);
	const { isCustomerCareViewingPvCustomer } = userContext;
	const [customTenant, setCustomTenant] = useState<Tenant>();
	const [showEditCustomProduct, setShowEditCustomProduct] = useState(false);
	const [showDeleteCustomProduct, setShowDeleteCustomProduct] =
		useState(false);
	const { enableUpBranchCode } = appContext.featureFlags;
	const activeTab = tab === 'details' ? TABS.DETAILS : TABS.USERS;
	const tabIndex = activeTab == TABS.DETAILS ? 1 : 0;

	const getColumns = (): GridColumn[] => {
		const sandboxColumn: Column<TenantRowData> = {
			id: 'sandbox',
			label: 'Type',
			cell: {
				value({ row }: { row: Tenant }) {
					return row.sandbox ? 'Sandbox' : 'Production';
				},
			},
			width: 100,
		};

		const ssoEnabledColumn: Column<ApplicationRowData> = {
			id: 'ssoEnabled',
			label: 'Product SSO activated',
			sortable: false,
			cell: {
				value({ row }: { row: Application }) {
					return row.foundationApp
						? 'N/A'
						: row.ssoEnabled.toString();
				},
			},
			width: 200,
		};

		const lastSyncColumn: Column<TenantRowData> = {
			id: 'lastSync',
			label: 'Last Sync',
			sortable: false,
			cell: {
				Renderer({ tabIndex, rowId }: CellRendererProps) {
					const row = useGridRow<Tenant>(rowId);
					const lastSync = row.lastSync;
					return lastSync ? (
						<GridCellDefault
							tabIndex={tabIndex}
							icon={
								row.lastSyncErrorMessage ? (
									<TileErrorMessage
										tenant={row}
										useIcon={true}
									/>
								) : undefined
							}
							label={formatDate({ intl, value: lastSync })}
						/>
					) : (
						<></>
					);
				},
			},
			width: 200,
		};

		const actionsColumn: Column<TenantRowData> = {
			id: 'actions',
			label: '',
			sticky: 'right',
			width: size.small,
			resizable: false,
			movable: false,
			sortable: false,
			cell: {
				Renderer({ tabIndex, rowId }: CellRendererProps) {
					const row = useGridRow<Tenant>(rowId);
					const { application, custom } = row;
					return custom || application === 'E1_PRM' ? (
						<ActionsMenu tabIndex={tabIndex} rowData={row} />
					) : (
						<></>
					);
				},
			},
		};

		const columns = [
			sandboxColumn,
			{
				id: 'envSelector',
				label: 'EnvSelector',
				width: 350,
			},
			{
				id: 'url',
				label: 'URL',
				width: 250,
			},
			{
				id: 'tenantGroupId',
				label: 'Tenant Group ID',
				width: 300,
			},
			{
				id: 'tenantRegion',
				label: 'Region',
				width: 100,
			},
			ssoEnabledColumn,
			lastSyncColumn,
			{
				// software version
				id: 'softwareVersion',
				label: 'Software Version',
				sortable: false,
				width: 150,
			},
			actionsColumn,
		];

		return columns;
	};

	const columns: GridColumn[] = getColumns();
	const preferencesKey = columns.map((c) => c.id).join('-');
	const preferencesAdapter = useLocalStoragePreferences(preferencesKey);

	useEffect(() => {
		const getCustomerDetails = async () => {
			if (customerId) {
				const customerResult =
					await requestWithErrorHandling<CustomerDto>({
						method: 'get',
						url: `/io/v1/admin/customer/${customerId}`,
						intl,
						appContext,
					});

				const { tenants = [] } = customerResult;
				const tenantsByEnvSelector = getTenantsByEnvSelector(tenants);
				setCustomer({
					...customerResult,
					tenantsByEnvSelector,
				});

				const { parentCustomerId } = customerResult;
				if (parentCustomerId) {
					const { title } =
						await requestWithErrorHandling<CustomerDto>({
							method: 'get',
							url: `/io/v1/admin/customer/${parentCustomerId}`,
							intl,
							appContext,
						});
					setParentTitle(title);
				}
			}
		};
		void getCustomerDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerId]);

	const ActionsMenu = ({
		tabIndex,
		rowData,
	}: {
		tabIndex: number;
		rowData: Tenant;
	}) => {
		const trigger = {
			tooltip: 'Actions',
			icon: <DotsVertical />,
		};

		const { application, envSelector, custom } = rowData;
		return (
			<>
				<GridCellDropdownMenu
					alignRight={true}
					trigger={trigger}
					tabIndex={tabIndex}
					label={intl.formatMessage(messages.actionsToolTip)}
				>
					<ListItem
						label="Portfolios GlobalOptions SQL Fix"
						disabled={application === E1_PRM}
						onActivate={() => {
							window.open(
								`/io/v1/admin/tenant/${envSelector.toString()}/globaloptionscript`,
							);
						}}
						hidden={custom || application !== 'E1_PRM'}
					/>
					<ListItem
						label="Edit custom product"
						onActivate={() => {
							setCustomTenant(rowData);
							setShowEditCustomProduct(true);
						}}
						hidden={!custom}
					/>
					<ListItem
						label="Delete custom product"
						onActivate={() => {
							setCustomTenant(rowData);
							setShowDeleteCustomProduct(true);
						}}
						hidden={!custom}
					/>
				</GridCellDropdownMenu>
			</>
		);
	};

	if (!customer) {
		return <></>;
	}

	const {
		tenants = [],
		title,
		regulatoryRegion,
		domain,
		uofpBranchCode,
		topDownUserManagementEnabled,
		ssoEnabled,
		samlNameIdLookupType,
		samlEntityId,
	} = customer;
	const rows: TenantRowData[] = tenants.map((tenant) => {
		return { id: tenant.tenantId, ...tenant };
	});

	const changeTab = (newTab: string) => {
		const link = `/pvadmin/orgs/${customerId}${
			newTab === TABS.DETAILS ? '/details' : ''
		}`;
		navigate(link, { state: { redirect: true } });
	};

	const showToolsMenu = !isCustomerCareViewingPvCustomer(customerId);
	const { tenantsByEnvSelector = {} } = customer;
	return (
		<CustomerContext.Provider value={{ customer, tenantsByEnvSelector }}>
			<CustomerInfoSection>{title}</CustomerInfoSection>
			<TabGroup index={tabIndex}>
				<Tab
					key={TABS.USERS}
					label={'Users'}
					onActivate={() => changeTab(TABS.USERS)}
				/>
				<Tab
					key={TABS.DETAILS}
					label={'Details'}
					onActivate={() => changeTab(TABS.DETAILS)}
				/>
			</TabGroup>

			{activeTab === TABS.USERS && (
				<TabBody>
					<UsersPage
						isCustomerCare={true}
						mode={UserTabs.CURRENT_USERS}
					/>
				</TabBody>
			)}

			{activeTab === TABS.DETAILS && (
				<TabBody style={{ overflowY: 'auto' }}>
					{showToolsMenu && (
						<Toolbar label="Organization details toolbar">
							<ToolbarToolsMenu customer={customer} />
						</Toolbar>
					)}
					<DetailTable>
						<tbody>
							<tr key="row-customer-id">
								<DetailHeader>ID:</DetailHeader>
								<CustomerData>{customerId}</CustomerData>
							</tr>
							<tr key="row-reg-region">
								<DetailHeader>Region:</DetailHeader>
								<CustomerData>{regulatoryRegion}</CustomerData>
							</tr>
							<tr key="row-domain">
								<DetailHeader>Domain:</DetailHeader>
								<CustomerData>{domain}</CustomerData>
							</tr>
							<tr key="row-parent">
								<DetailHeader>
									Parent organization:
								</DetailHeader>
								<CustomerData>{parentTitle}</CustomerData>
							</tr>
							{enableUpBranchCode && (
								<tr key="row-branch-code">
									<DetailHeader>
										SFDC ID number / UP branch code:
									</DetailHeader>
									<CustomerData>
										{uofpBranchCode}
									</CustomerData>
								</tr>
							)}
							<tr key="row-tdum-enabled">
								<DetailHeader>TDUM enabled?:</DetailHeader>
								<CustomerData>
									{topDownUserManagementEnabled.toString()}
								</CustomerData>
							</tr>
							<tr key="row-uses-saml-auth">
								<DetailHeader>SSO enabled?:</DetailHeader>
								<CustomerData>
									{ssoEnabled.toString()}
								</CustomerData>
							</tr>
							{ssoEnabled && (
								<tr key="row-saml-name-id-lookup">
									<DetailHeader>
										SAML NameID lookup type:
									</DetailHeader>
									<CustomerData>
										{samlNameIdLookupType}
									</CustomerData>
								</tr>
							)}
							{ssoEnabled && (
								<tr key="row-saml-identifier">
									<DetailHeader>
										SAML identifier (Entity ID):
									</DetailHeader>
									<CustomerData>{samlEntityId}</CustomerData>
								</tr>
							)}
						</tbody>
					</DetailTable>
					<TenantHeader>Tenants:</TenantHeader>
					<Container>
						<Grid
							columns={columns}
							rows={rows}
							selectionMode="none"
							preferencesAdapter={preferencesAdapter}
							defaultSort={[
								{
									columnId: 'sandbox',
									direction: 'asc',
								},
							]}
							multiColumnSort={false}
						/>
					</Container>
					{showEditCustomProduct && customTenant ? (
						<EditCustomProductDialog
							customerId={customer.id}
							tenant={customTenant}
							setShowEditCustomProduct={setShowEditCustomProduct}
						/>
					) : null}
					{showDeleteCustomProduct ? (
						<DeleteCustomProductDialog
							tenant={customTenant}
							setShowDeleteCustomProduct={
								setShowDeleteCustomProduct
							}
						/>
					) : null}
				</TabBody>
			)}
		</CustomerContext.Provider>
	);
};

export default OrganizationInfoPage;
