import { defineMessages } from 'react-intl';

const messages = defineMessages({
	idColumn: {
		id: 'admin.users.column.id',
		description: 'Label for ID column in CSV export',
		defaultMessage: 'ID',
	},
	userIDColumn: {
		id: 'admin.users.column.userID',
		description: 'Label for User ID column in Users Grids',
		defaultMessage: 'User ID',
	},
	firstNameColumn: {
		id: 'admin.users.column.firstName',
		description: 'Label for First Name column for Users Grids',
		defaultMessage: 'First name',
	},
	lastNameColumn: {
		id: 'admin.users.column.lastName',
		description: 'Label for Last Name column for Users Grids',
		defaultMessage: 'Last name',
	},
	emailColumn: {
		id: 'admin.users.column.email',
		description: 'Label for Email column for Users Grids',
		defaultMessage: 'Email',
	},
	usernameColumn: {
		id: 'admin.users.column.username',
		description: 'Label for Username column for Users Grids',
		defaultMessage: 'Username',
	},
	loginAliasColumn: {
		id: 'admin.users.column.loginAlias',
		description: 'Label for Login alias column displayed in CSV export',
		defaultMessage: 'Login alias',
	},
	adminColumn: {
		id: 'admin.users.column.admin',
		description: 'Label for Admin column for Users Grids',
		defaultMessage: 'Administrator',
	},
	localAuthColumn: {
		id: 'admin.users.column.passwordLogin',
		description: 'Label for Password required column for Users Grids',
		defaultMessage: 'Password required',
	},
	productsColumn: {
		id: 'admin.users.column.products',
		description: 'Label for Products column for Users Grids',
		defaultMessage: 'Products',
	},
	statusColumn: {
		id: 'admin.users.column.status',
		description: 'Label for Status column for Users Grids',
		defaultMessage: 'Status',
	},
	activeUser: {
		id: 'admin.users.column.status.active',
		description: 'Text content for active user in status column',
		defaultMessage: 'Active',
	},
	deactivatedUser: {
		id: 'admin.users.column.status.deactivated',
		description: 'Text content for deactivated user in status column',
		defaultMessage: 'Deactivated',
	},
	pendingUser: {
		id: 'admin.users.column.status.pending',
		description:
			'Text content for user without password yet in status column',
		defaultMessage: 'Pending',
	},
	lastLoginAtColumn: {
		id: 'admin.users.column.lastLoginAt',
		description: 'Label for Last login column for Users Grids',
		defaultMessage: 'Last login',
	},
	sandboxSuffix: {
		id: 'admin.users.column.sandboxSuffix',
		description:
			'Suffix appended to the product name to denote it is sandbox',
		defaultMessage: '(Sandbox)',
	},
	exportYes: {
		id: 'admin.users.export.yes',
		description: 'Used in user export for showing when a check is yes/true',
		defaultMessage: 'Yes',
	},
	exportNo: {
		id: 'admin.users.export.no',
		description: 'Used in user export for showing when a check is no/false',
		defaultMessage: 'No',
	},
});

export default messages;
