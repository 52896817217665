import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import UsersPage from '../../admin/users/UsersPage';
import messages from './UsersPage.messages';
import { Tab, TabGroup } from '@planview/pv-uikit';
import { theme, spacingPx } from '@planview/pv-utilities';
import { useNavigate, useParams } from 'react-router-dom';

export enum UserTabs {
	CURRENT_USERS,
	DEACTIVATED_USERS,
}

const StyledTabGroup = styled(TabGroup)`
	background-color: ${theme.backgroundNeutral0};
`;

const TabBody = styled.div`
	flex-grow: 1;
	min-height: 0;
	padding-top: ${spacingPx.xsmall};
	display: flex;
	flex-direction: column;
`;

const tabMap: { [key: string]: UserTabs } = {
	current: UserTabs.CURRENT_USERS,
	deactivated: UserTabs.DEACTIVATED_USERS,
};

type UsersTabPageParams = {
	tab: string;
};

const UsersTabsPage = () => {
	const { tab = '' } = useParams<UsersTabPageParams>();
	const tabIndex = tabMap[tab];
	const navigate = useNavigate();
	const intl = useIntl();

	const availableTabs = [UserTabs.CURRENT_USERS, UserTabs.DEACTIVATED_USERS];
	const currentTab = availableTabs[tabIndex];

	const labels: { [tab: string]: () => string } = {
		[UserTabs.CURRENT_USERS]: () =>
			intl.formatMessage(messages.currentUsersTab),
		[UserTabs.DEACTIVATED_USERS]: () =>
			intl.formatMessage(messages.deactivatedUsersTab),
	};

	const changeTab = (newTab: UserTabs) => {
		let tabName;
		switch (newTab) {
			case UserTabs.DEACTIVATED_USERS:
				tabName = 'deactivated';
				break;
			default:
				tabName = 'current';
		}
		navigate(`/admin/users/${tabName}`, { state: { redirect: true } });
	};

	return (
		<React.Fragment>
			{availableTabs.length > 1 && (
				<StyledTabGroup defaultIndex={tabIndex}>
					{availableTabs.map((tabType) => (
						<Tab
							key={tabType}
							onActivate={() => changeTab(tabType)}
							label={labels[tabType]()}
						/>
					))}
				</StyledTabGroup>
			)}
			{currentTab === UserTabs.CURRENT_USERS && (
				<TabBody>
					<UsersPage isCustomerCare={false} mode={currentTab} />
				</TabBody>
			)}
			{currentTab === UserTabs.DEACTIVATED_USERS && (
				<TabBody>
					<UsersPage isCustomerCare={false} mode={currentTab} />
				</TabBody>
			)}
		</React.Fragment>
	);
};

export default UsersTabsPage;
