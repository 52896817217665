import { LicenseType } from './api/licenses';
import { Role } from './api/roles';
import { TenantsByEnvSelector } from './api/tenants';

export class EnvSelector {
	static readonly delimiter = '~';

	application: ApplicationName;
	tenantId: string;

	constructor(application: string, tenantId: string) {
		this.application = application as ApplicationName;
		this.tenantId = tenantId;
	}

	toString(): string {
		return `${this.application}${EnvSelector.delimiter}${this.tenantId}`;
	}

	static fromString(envSelector: string): EnvSelector {
		const [application, tenantId] = envSelector.split(
			EnvSelector.delimiter,
		);
		return new EnvSelector(application, tenantId);
	}

	static fromTenant(tenant: Tenant): EnvSelector {
		return new EnvSelector(tenant.application, tenant.tenantId);
	}
}

export const noOp = (): void => undefined;

/**
 * Backend names for applications - see Application.java enum
 */
export type ApplicationName =
	| 'ADAPTIVEWORK'
	| 'ADVISOR'
	| 'COMMUNITY'
	| 'COPILOT_ADMIN'
	| 'E1_PRM'
	| 'EA'
	| 'HUB'
	| 'LAUNCHPAD'
	| 'LEANKIT'
	| 'LOGBOOK'
	| 'LOGBOOK_REPORTING'
	| 'OKRS'
	| 'OKRS_REPORTING'
	| 'PLANVIEW_ME'
	| 'PPMPRO'
	| 'PROJECTPLACE'
	| 'PVADMIN'
	| 'ROADMAPS'
	| 'SUCCESS'
	| 'TEAMS'
	| 'TEAMTAP'
	| 'UP'
	| 'VIZ';

export type Region = 'US' | 'EU' | 'AP';

export interface FeatureFlag {
	enableNotificationsModule?: boolean;
	enableTeamtap?: boolean;
	enableLaunchpad?: boolean;
	enableViz?: boolean;
	enableHub?: boolean;
	enableEa?: boolean;
	topDownUserManagement?: boolean;
	enableManualTdumUserSync?: boolean;
	enableSamlIssuerValidation?: boolean;
	enableTdumUserSync?: boolean;
	enableMultipleProdInstances?: boolean;
	enablePendo?: boolean;
	enableIdeaplaceLicensing?: boolean;
	enableAllTenantsDeletable?: boolean;
	enableLogbook?: boolean;
	enableUpBranchCode?: boolean;
	enableCommunity?: boolean;
	enableSuccess?: boolean;
	enableCrossOrg?: boolean;
	enableGroups?: boolean;
	enableUsernames?: boolean;
	enableCustomerDomain?: boolean;
	enableDarkMode?: boolean;
}

export interface Application {
	domain: string;
	envSelector: EnvSelector;
	username: string | null;
	sandbox: boolean;
	title: string;
	licenseTypes: LicenseType[];
	roles: Role[];
	defaultRole: Role | null;
	showPlanviewAdminSSO: boolean;
	setPrimarySandboxSupported: boolean;
	showUserSync: boolean;
	shouldShowAddedOnColumn: boolean;
	filterable: boolean;
	navigateUrl: string;
	tenantGroupId: string;
	foundationApp: boolean;
	userAssignmentSupported: boolean;
	deletable: boolean;
	primary: boolean;
	ssoEnabled: boolean;
	hideFromOverview: boolean;
	custom: boolean;
	serviceApp: boolean;

	/**
	 * the Planview product name, e.g. "AgilePlace", "Portfolios", etc
	 */
	productName: string;

	// added via GlobalState
	showPrimaryLabel: boolean;
	envSelectorEncodedString: string;
	envSelectorString: string;
	app: ApplicationName;
}

export interface User {
	id: string;
	customer?: Customer;
	firstName: string;
	lastName: string;
	email: string;
	isAdmin: boolean;
	isCustomerCare: boolean;
	isSuperAdmin: boolean;
	isSolutionConsultant: boolean;
	localAuthOverrideEnabled: boolean;
	isActive: boolean;
	customerSsoEnabled: boolean;
	loginAlias?: string;
	authenticationType?: string;
	systemGroupIds?: string[];

	// added via GlobalState
	name: string;
	passwordUser: boolean;
}

export interface UserLoginProps {
	username: string;
	password: string;
	captcha: string;
	customerId: string;
}

export interface Customer {
	id: string;
	parent?: boolean;
	parentCustomerId?: string;
	parentCustomerTitle?: string;
	title: string;
	domain?: string;
	topDownUserManagementEnabled: boolean;
	uofpBranchCode?: string;
	orgTags: string[];
	orgHierarchyEnabled: boolean;
	ssoEnabled: boolean;
	regulatoryRegion: string;
	pendingDeletionTime?: string;
	samlNameIdLookupType: string;
	samlEntityId?: string;
	tenants?: Tenant[];
	tenantsByEnvSelector?: TenantsByEnvSelector;
	emailEnabled: boolean;
	hideServiceTiles: boolean;
}

export interface Tenant {
	roles: Role[];
	licenseTypes: LicenseType[];
	application: ApplicationName;
	url: string;
	title: string;
	primary: boolean;
	notes: string | null;
	customerId: string;
	tenantGroupId: string;
	tenantId: string;
	sandbox: boolean;
	tenantRegion: Region | null;
	mappedToAllUsers: boolean;
	lastSync: string | null;
	lastSyncErrorMessage?: string;
	filterable: boolean;
	syncCount: number;
	syncStart: string | null;
	ssoEnabled: boolean;
	envSelector: EnvSelector;
	productName: string;
	softwareVersion: string | null;
	unmappedUsersCount?: number;
	custom: boolean;
}

export interface TenantGroup {
	tenantGroupId: string;
	sandbox: boolean;
	primary: boolean;
	title: string;
}
